<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <CarrotTitle title="사보 등록">
            - 첨부파일은 각각 최대 2MB까지 파일을 올리실 수 있습니다.
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>
                    <div class="mb-20">
                        <span class="float-right">＊필수 입력 항목입니다.</span>
                        <div class="clear"></div>
                    </div>
                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th><span class="txt-red">*</span> 사보명</th>
                                <td>
                                     <input type="text" v-model="bbs.magazine_name" class="w-100per">
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 제목</th>
                                <td>
                                    <input type="text" v-model="bbs.title" class="w-100per">
                                </td>
                            </tr>
                            <tr>
                              <th>제목(영문)</th>
                              <td>
                                <input type="text" v-model="bbs.title_en" class="w-100per">
                              </td>
                            </tr>
                            <tr>
                              <th><span class="txt-red">*</span> 미리보기(한글)</th>
                              <td>
                                <input type="text" v-model="bbs.preview_ko" class="w-100per">
                              </td>
                            </tr>
                            <tr>
                              <th>미리보기(영문)<br>(ITM/CTM메인 노출용 영문)</th>
                              <td>
                                <input type="text" v-model="bbs.preview_en" class="w-100per">
                              </td>
                            </tr>
                            <!--
                            <tr>
                              <th><span class="txt-red">*</span> 배경이미지<br>(인트라넷)</th>
                              <td>
                                <CarrotFileUploader ref="file4"></CarrotFileUploader>
                              </td>
                            </tr>
                            -->
                            <tr>
                                <th><span class="txt-red">*</span>썸네일</th>
                                <td>
                                    <CarrotFileUploader ref="file1"></CarrotFileUploader>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span>본문 jpg</th>
                                <td>
                                    <CarrotFileUploader ref="file2"></CarrotFileUploader>
                                </td>
                            </tr>
                            <tr>
                              <th>본문 jpg(영문)<br>(ITM/CTM메인 노출용 영문)</th>
                              <td>
                                <CarrotFileUploader ref="file4"></CarrotFileUploader>
                              </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span>크게 보기(PDF)</th>
                                <td>
                                    <CarrotFileUploader ref="file3"></CarrotFileUploader>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <button class="btn-default float-left mt-30" @click="bbs.showList">목록</button>
                    <button class="btn-default float-right mt-30" @click="bbs.onSave">등록하기</button>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src 
import { onMounted, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'

export default {
    layout:"ResourceManagement",
    components: {
      //CarrotDatePicker,        
      // CarrotEditor
    },
    setup() {
        const router = useRouter();
        const toast = useToast();

        const file1 = ref(null);
        const file2 = ref(null);
        const file3 = ref(null);
        const file4 = ref(null);
        const bbs = reactive({
            topfix: false,
            title : "",
            title_en : "",
            preview_ko : "",
            preview_en:"",
            content: "",
            magazine_name : "",

            showList: () => {
                router.push({ name:"ResourceManagement-MagazineManagementList" });
            },

            onSave: () => {
                if( !bbs.magazine_name ){
                    toast.error("사보명을 입력하세요.");
                    return;
                }
                if( !bbs.title ){
                    toast.error("제목을 입력하세요.");
                    return;
                }
                if( !bbs.preview_ko ){
                  toast.error("미리보기(한글)을 입력하세요.");
                  return;
                }
                var formData = new FormData();
                formData.append("magazine_name", bbs.magazine_name);
                formData.append("title", bbs.title);
                formData.append("title_en", bbs.title_en);
                formData.append("preview_en", bbs.preview_en);
                formData.append("preview_ko", bbs.preview_ko);
                if(file1.value.file.filedata!==null) {
                    formData.append("nfile[]", file1.value.file.filedata);
                }
                if(file2.value.file.filedata!==null) {
                    formData.append("nfile[]", file2.value.file.filedata);
                }
                if(file3.value.file.filedata!==null) {
                    formData.append("nfile[]", file3.value.file.filedata);
                }
                if(file4.value.file.filedata!==null) {
                  formData.append("nfile[]", file4.value.file.filedata);
                }
                axios.post('/rest/resourceManagement/magazineAdd', formData, {
                    headers: {
                    'Content-Type': 'multipart/form-data'
                    }
                }).then((res)=>{
                    if( res.data.err == 0 ){
                        router.push({ 
                            name:"ResourceManagement-MagazineManagementView-idx",
                            params : { idx:res.data.idx }
                        });
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            }
        });
        

        onMounted(() => {
            // Mounted

        });

        return {bbs, file1, file2, file3, file4};
    }
}
</script>

<style lang="scss" scoped>
</style>